<template>
  <div class="bg-black flex justify-between items-center h-56 px-4 lg:px-8">
    <picture>
      <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-1.png', {
            width: 97,
            format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-1.png', {
            width: 194,
            format: 'webp',
          })} 2x
        `"
      />
      <img
        alt=""
        width="97"
        height="47"
        :src="$img('/assets/img/gg-shiver/ghw-1.png', { width: 97 })"
      />
    </picture>
    <picture>
      <source
        type="image/webp"
        media="(max-width: 1023px)"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-2.png', {
            width: 218,
            format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-2.png', {
            width: 436,
            format: 'webp',
          })} 2x
        `"
      />
      <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-2-lg.png', {
            width: 700,
            format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-2-lg.png', {
            width: 1400,
            format: 'webp',
          })} 2x
        `"
      />
      <source
        type="image/png"
        :srcset="$img('/assets/img/gg-shiver/ghw-2-lg.png', { width: 700 })"
      />
      <img
        alt=""
        width="218"
        height="36"
        :src="$img('/assets/img/gg-shiver/ghw-2.png', { width: 218 })"
      />
    </picture>
    <picture>
      <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-3.png', {
            width: 40,
            format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-3.png', {
            width: 80,
            format: 'webp',
          })} 2x
        `"
      />
      <img
        alt=""
        width="40"
        height="40"
        :src="$img('/assets/img/gg-shiver/ghw-3.png', { width: 40 })"
      />
    </picture>
  </div>
</template>
